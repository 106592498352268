import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import { BgImage } from 'gbimage-bridge';
import { legalNoticeStyles } from './styles';

const TermsAndConditionsPage = () => {
  const { backgroundImagePrices } = useStaticQuery(
    graphql`
      query {
        backgroundImagePrices: file(relativePath: { eq: "heroBackground.jpeg" }) {
          childImageSharp {
            gatsbyImageData(width: 2000, quality: 50, webpOptions: { quality: 70 })
          }
        }
      }
    `,
  );
  const pluginImage = getImage(backgroundImagePrices);

  // const LegalNotice = () => {
  const classes = legalNoticeStyles();
  return (
    <>
      <BgImage image={pluginImage} className="masthead2 imagesBlur">
        <Grid container spacing={2} className={classes.headImagesTextPosition}>
          <Grid item lg={12} md={12} xs={12}>
            <Box className={classes.boxImagesNoContent}>
              <Typography className={classes.siteTitle}>{/* {t('trans.privacyPolicyHead')} */}</Typography>
            </Box>
          </Grid>
        </Grid>
      </BgImage>
      {/* <Box className={classes.boxoben} /> */}

      <Box className={classes.box}>
        <h1 className="adsimple-321244783">Allgemeine Geschäftsbedingungen</h1>

        <p>Noch nicht vordanden!</p>

        <p className="adsimple-321244783">
          Hirten Technologies
          <br />
          Augustastraße 1 <br />
          57555 Mudersbach <br />
          Deutschland
        </p>
        <p className="adsimple-321244783" />
        <p className="adsimple-321244783">
          <strong>Tel.:</strong> 0151 473 893 30
          <br />
          <strong>E-Mail:</strong> <a href="mailto:info@d-simple.com">info@d-simple.com</a>
        </p>
      </Box>
    </>
  );
};

export default TermsAndConditionsPage;
